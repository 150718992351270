<template>
	<div class="sidebar sidebar-dark sidebar-main sidebar-expand-xs" :class="{ 'sidebar-fullscreen': sidebarFullscreen }">
		<div class="sidebar-mobile-toggler text-center">
			<a href="#" class="sidebar-mobile-main-toggle" @click="onSidebarHide">
				<i class="icon icon-chevron-left"></i>
			</a>
			Главное меню
			<a href="#" class="sidebar-mobile-expand" @click="onChangeSidebarFullscreen">
				<i class="icon icon-maximize"></i>
				<i class="icon icon-minimize"></i>
			</a>
		</div>

		<div class="sidebar-content">
			<div class="card card-sidebar-mobile">
				<ItemsSidebar :items="menuItems" ref="sidebar" />
			</div>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue';

import { closeSidebar, menuItems, sidebarFullscreen } from "../layouts";

import ItemsSidebar from "./ItemsSidebar";
import { config } from "@/config";
import { useStore } from 'vuex';

export default {
	components: {
		ItemsSidebar
	},

	setup() {
		const { state } = useStore();

		return {
			userAvatar: computed(() => `${config.server}/api/images/avatar/${state.user.avatar ? state.user.avatar : null}`),
			menuItems: computed(() => menuItems.value),

			sidebarFullscreen: computed(() => sidebarFullscreen.value),

			onChangeSidebarFullscreen: () => sidebarFullscreen.value = !sidebarFullscreen.value,

			onSidebarHide: () => closeSidebar()
		}
	}
};
</script>

<style></style>
