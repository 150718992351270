<template>
	<li class="nav-item dropdown dropdown-user" :class="{ show }" v-if="isLogin" v-clickaway>
		<a href="#" class="navbar-nav-link d-flex align-items-center dropdown-toggle" @click.prevent="openPopup">
			<img :src="userAvatar" class="rounded-circle mr-2" height="34" alt />
			<span>{{ userName }}</span>
		</a>

		<div class="dropdown-menu dropdown-menu-right" :class="{ show }">
			<a href="#" class="dropdown-item" @click.prevent="reload">
				<i class="icon icon-arrow-sync"></i> Обновить
			</a>
			<router-link to="/profile" class="dropdown-item">
				<i class="icon icon-user"></i> Мой профиль
			</router-link>
			<a href="#" class="dropdown-item" @click.prevent="onLogout">
				<i class="icon icon-log-out"></i> Выйти
			</a>
		</div>
	</li>

	<li class="nav-item dropdown dropdown-user" v-else>
		<router-link class="navbar-nav-link d-flex align-items-center" to="/login">
			<span>
				<i class="icon icon-log-in"></i> Войти
			</span>
		</router-link>
	</li>
</template>
<script>

import { computed } from 'vue';
import { useStore } from 'vuex';
import { config } from "@/config";
import { clickaway } from "@/mixins";

export default {
	mixins: [clickaway],

	setup() {
		const { state, dispatch } = useStore();

		return {
			reload: () => window.location.reload(true),
			isLogin: computed(() => state.token && state.user.exp),
			userName: computed(() => state.user.name),
			userAvatar: computed(() => `${config.server}/api/images/avatar/${state.user.avatar ? state.user.avatar : null}`),
			onLogout: () => dispatch('logout')
		}
	}
}
</script>
