<template>
	<div class="input-group mb-1">
		<input v-focus :placeholder="placeholder" class="form-control" v-model="text" @keydown.enter.prevent="onSearch" ref="input" />
		<div class="input-group-append">
			<button type="button" class="btn btn-trig" @click="onSearch" v-if="!searchAway">
				<i class="icon icon-search1"></i>
			</button>
			<button type="button" class="btn btn-trig" @click="onClear">
				<i class="icon icon-x"></i>
			</button>
		</div>
	</div>
</template>

<script lang="ts">

import { ref, defineComponent, watch } from 'vue';

export default defineComponent({
	props: {
		modelValue: {
			type: String,
			default: ''
		},
		searchAway: {
			type: Boolean,
			default: false
		},
		delay: {
			type: Number,
			default: 250
		},
		placeholder: {
			type: String,
			default: 'Введите строку для поиска...'
		},
		search: {
			type: Boolean,
			default: true
		},
		clear: {
			type: Boolean,
			default: true
		}
	},

	emits: ['update:modelValue'],

	setup(props: any, { emit }: { emit: any }) {
		const input = ref(null);

		const text = ref<string>(props.modelValue);

		if (props.searchAway) {
			let handle: any = null;
			watch(
				text,
				(newValue) => {
					if (handle != null) clearTimeout(handle);

					handle = setTimeout(() => emit('update:modelValue', newValue), props.delay);
				}
			)
		}

		watch(
			() => props.modelValue,
			(value) => emit('update:modelValue', text.value = value)
		)

		const onSearch = () => emit('update:modelValue', text.value);

		const onClear = () => emit('update:modelValue', text.value = '');

		return {
			onClear, onSearch, text, input
		}
	}
})
</script>

<style></style>
