import { createRouter, createWebHistory } from "vue-router";
import { breadCrumb, closeAllPanels, pageTitle, panels, setCaption } from "@/layouts/layouts";

import Bus from '@/core/bus';
import stateStore from "@/store"

const routes = [
	{
		path: '',
		component: () => import('@/layouts/Panels.vue'),
		children: [
			{
				path: '',
				component: () => import('@/views/ActiveCalls.vue'),
				meta: {
					title: 'Главная'
				}
			},
			{
				path: '/arhive',
				component: () => import('@/views/ArhiveCalls.vue'),
				meta: {
					title: 'Архив'
				}
			},
			{
				path: '/patients',
				component: () => import('@/tables/patients/forms/List.vue'),
				meta: {
					roles: ['admin', 'patients', 'brigade'],
					title: 'Картотека пациентов',
				}
			},
			{
				path: '/patients/:id',
				component: () => import('@/tables/patients/forms/List.vue'),
				meta: {
					roles: ['admin', 'patients', 'brigade'],
					title: 'Картотека пациентов',
				}
			},
			{
				path: '/tables',
				component: () => import('@/views/ViewTables.vue'),
				meta: {
					title: 'Список таблиц',
					roles: ['admin']
				}
			},
			{
				path: '/tables/:table',
				component: () => import('@/tables/List.vue'),
				meta: {
					title: 'Таблица'
				}
			},
			{
				path: '/messages',
				component: () => import('@/views/messages.vue'),
				meta: {
					title: 'Сообщения'
				}
			}
		]
	},
	{
		path: '/profile',
		component: () => import('@/views/profile/index.vue'),
		meta: {
			title: 'Профиль',
			breadCrumb: [
				{
					route: '/profile',
					icon: 'icon icon-user',
					caption: 'Профиль'
				}
			]
		},
		children: [
			{
				path: '',
				component: () => import('@/views/profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-more-horizontal',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open',
							caption: 'Основные данные'
						}
					]
				}
			},
			{
				path: 'page',
				component: () => import('@/views/profile/page.vue'),
				meta: {
					title: 'Основные данные',
					icon: 'icon icon-book-open',
					breadCrumb: [
						{
							route: '/profile',
							icon: 'icon icon-user',
							caption: 'Профиль'
						},
						{
							icon: 'icon icon-book-open',
							caption: 'Основные данные'
						}
					]
				}
			}
		]
	},
	{
		path: '/user/:guid',
		component: () => import('@/views/UserProfile/index.vue'),
		meta: {
			title: 'Профиль'
		},
		children: [
			{
				path: '',
				component: () => import('@/views/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			},
			{
				path: 'aboutuser',
				component: () => import('@/views/UserProfile/AboutUser.vue'),
				meta: {
					title: 'О пользователе',
					icon: 'icon-user'
				}
			}
		]
	},
	{
		path: '/login',
		component: () => import('@/views/auth/Login.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/404',
		component: () => import('@/views/Page404.vue')
	},
	{
		path: '/:pathMatch(.*)',
		component: () => import('@/views/Page404.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

Bus.$on('ws-open-document', (data: any) => {
	switch (data.type_document) {
		default:
			// console.log(data);

			// router.push(`pgi_research/${data.id}`);

			break;
	}
});

router.beforeEach(async (to, from) => {
	//Прописывать в роутах роли и проверять доступ при переходах
	if (to.fullPath != from.fullPath) {
		for (const panel of panels.value) {
			if (panel.onBeforeClose) {
				if (!await panel.onBeforeClose()) return false;
			}
		}

		closeAllPanels();

		setCaption('');
	}

	//Получаем статус пользователя
	const user: any = stateStore.state.user.id ? stateStore.state.user : await stateStore.dispatch('status');

	//Если в метаданных прописаны роли, то производим проверку на доступ, если не указана ни одна роль - доступ запрещен
	if (to.meta.roles) {
		const roles: any = to.meta.roles;

		for (const routeRole of roles) {
			if (user.roles.includes(routeRole)) return true;
		}

		return { path: '/404' };
	} else {
		return true;
	}
})

router.afterEach((to) => {
	// breadCrumb.value = to.meta.breadCrumb ? to.meta.breadCrumb : [];

	// pageTitle.value = to.meta.title ? to.meta.title : null;
})

export default router;
